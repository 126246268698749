<!-- 我的筑龙页面 -->
<!-- <router-link :to="{path:'/useragreement'}">《用户信息协议》</router-link> -->
<template>
  <div class="main">
    <h1 style="text-align: center">筑龙学社隐私政策</h1>
    <div class="ne-viewer-body">
      <ne-p>
        <ne-text id="ubcda5eda">
          更新日期：2020年12月28日
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ua728224b">
          版本生效日期：2021年1月11日
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc14632f2">
          筑龙学社是由北京筑龙伟业科技股份有限公司（以下简称“筑龙学社”）为您提供的一款在线教育产品。筑龙学社（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc2a6c1b4">
          请在使用我们的产品或服务前，仔细阅读并了解本《筑龙学社隐私政策》。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u88a2f07c">
          一、我们如何收集和使用您的个人信息
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u97de6b20">
          个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u09a0629a">
          我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ub86eec3c">
          （一）您须授权我们收集和使用您个人信息的情形
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc7a77d2f">
          我们的产品或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能、改进我们产品或服务所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品或服务。这些功能包括：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u03e421ee">
          1、实现网上购物所必须的功能
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u8a352cf1"> （1）用户注册
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc1785e2f">
          您首先需要注册一个筑龙学社账户成为筑龙学社用户。当您注册时，您需要至少向我们提供您准备使用的筑龙学社账户名、密码、您本人的手机号码，我们将通过发送短信验证码的方式来验证您的身份是否有效。您的账户名为您的默认昵称，您可以修改补充您的昵称，这些信息均属于您的“账户信息”。您补充的账户信息将有助于我们为您提供更优质的购物体验，但如果您不提供这些补充信息，不会影响您网上购物的基本功能。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u613b6e7b"> （2）商品信息展示和搜索
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ud2c93cd1">
          为了让您快速地找到您所需要的商品，我们可能会在后台状态下收集您使用我们的产品或服务的手机状态信息（包括设备名称、设备型号、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、IOS广告标识符（IDFA）、SIM识别卡（ICCID）、国际移动用户识别码（IMSI）、ANDROID
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u3ce361e9">
          ID信息、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、网络状态、浏览器类型来为您提供商品信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ua2a96aef">
          您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc3796fbc"> （3）下单</ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc503e654">
          当您准备对您购物车内的商品进行结算时，筑龙学社系统会生成您购买该商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、您应支付的货款金额及支付方式。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u9b1ffe95"> （4）支付功能
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u41dd1cef">
          在您下单后，您可以选择筑龙学社合作的第三方支付机构（包括京东支付、微信支付及支付宝支付等支付通道，
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="udae9af92">
          以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的筑龙学社订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u7c4abc85"> （5）交付产品或服务功能
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uec286055">
          在当您下单并在线完成支付后，与筑龙学社合作的第三方配送公司（包括京东、顺丰、圆通等，以下称“配送公司”）将为您完成订单的交付。您知晓并同意与筑龙学社合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u79defece"> （6）客服与售后功能
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u4b61c088">
          我们的电话客服和售后功能会使用您的账号信息和订单信息。为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您若与我们的客服人员沟通，需要通过拨打电话提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u287d0598">
          2、改进我们产品或提供服务所必须的功能 （1）退费或重学等服务协议签订
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u3c867ab7">
          当您购买的商品中包含退费或重学等需要签订协议的服务时，为完成协议的签署，您需要提供本人姓名、本人身份证号码、本人手机号码、电子邮箱等信息，这些信息将用于您购买的商品服务协议签订及后续服务中，将在您后续行使服务权时，作为您本人的身份信息凭证。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u7d6a0d1a"> （2）退费或重学服务申请
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u2e564a8b">
          当您购买的商品中包含退费或重学服务时，且完成了协议签署，在服务申请有效期内，需向我们提供本人姓名、本人手机号码、退费收款银行卡账号、开户行、开户人、当年相关考试成绩单、当年准考证照片以及个人身份证正面照,这些信息将用于服务申请资格审核，验证您是否满足服务协议生效条件。为完成信息上传，需要您开启基于摄像头（相机）和图片上传的附加功能，用于实现考试成绩单、准考证及个人身份证正面照的拍摄和上传功能。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uf34bb5b1">二 权限名 权限用途
          权限说明
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u0ed05094">
          android.permission.CALL_PHONE允许程序从非系统拨号器里输入电话号码
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u5601ce84">
          当您与平台的客服取得联系时，平台的系统可能会记录您的电话号码以便核验身份。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc788b661">
          android.permission.WRITE_EXTERNAL_STORAGE|android.permission.READ_EXTERNAL_STORAGE
          读取及写入存储器权限
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u6f495587">
          当您为Android系统用户，进入APP时，为了正常使用APP内运行所需资源、图片、视频、文件、资料、讲义下载/更新APP/视频录制/图片预览/日志缓存/磁盘空间判断，以及使用课程下载、作业文件上传功能，平台需要获取您的读取及写入存储器权限。如不开启权限，您将不能使用与此权限相关的特定功能，但不影响您使用APP提供的其他服务。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uf2f26c96">
          android.permission.READ_CALENDAR
          android.permission.WRITE_CALENDAR读取及写入日历权限
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u00e42287">
          当您购买课程并上课时，为了能通过系统日历了解课程的上课时间并给出上课提醒，平台需要获取您的日历权限。如不开启权限，上课时间无法在系统日历上体现和提醒，但不影响您使用APP提供的上课学习和其他服务。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ude05dc9f">
          android.permission.CAMERA
          允许程序访问摄像头进行拍照
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u98e8afcf">
          当您使用头像图片设置、辅导监课、社区内容上传等功能时，为了您进行实时拍摄，平台将获取您的摄像头权限。如不开启权限，您将无法使用与实时拍摄相关的特定功能，但不影响您使用APP提供的其他服务。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u7f8c58f1">
          android.permission.READ_PHONE_STATE
          允许程序访问电话状态
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u3e56c511">
          平台会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（设备型号、操作系统版本、设备设置、唯一设备标识符软硬件特征信息）、设备所在位置相关信息（IP地址、GPS/北斗位置信息以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站传感器信息）。若您不提供设备相关信息，当您使用功能并遇到问题时，平台将无法及时排查到问题原因；若您不提供位置相关信息，我们将无法为您展示当地的课程及服务中心，但不会影响您使用APP的其他功能。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ub53bb187">
          android.permission.SYSTEM_ALERT_WINDOW
          允许程序使用悬浮窗权限
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u4cce2009">
          当您在直播期间购课时，直播将会以浮窗方式展示，平台需要获取您的使用悬浮窗权限。如不开启权限，您将无法展示悬浮窗，但不影响您使用APP提供的其他服务。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u71a13a47">
          android.permission.RECORD_AUDIO
          允许程序使用麦克风权限
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u00b245a9">
          当您使用直播语音等功能时，为了使用麦克风设备进行语音输入，平台需要获取您的麦克风权限。如不开启权限，您将无法使用与语音输入相关的特定功能，但不影响您使用APP提供的其他服务。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u4999ada1">
          （二）我们从第三方获得您个人信息的情形
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u045006f3">
          \t我们可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您的筑龙学社账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u092ed02d">
          （三）第三方SDK统计服务，改善我们的产品或服务
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uf480e1e7">
          我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们在后台状态下收集的数据可能包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、IOS广告标识符（IDFA）、SIM识别卡（ICCID）、国际移动用户识别码（IMSI）、ANDROID
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uac94df44">
          ID信息、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u07262c77" >
          目前，我们接入的<span style="color:#ee2e2e">第三方服务商</span>主要包括以下几种类型：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ue136dd20">
          1、第三方SDK“支付宝”、“微信支付”、“京东支付”，用于支付相关服务及汇总/统计支付信息：包括订单支付、交易行为核验、收入结算、支付信息汇总统计，需要获取您的<span style="color:#ee2e2e">设备信息</span>，您可以进一步查阅《支付宝隐私政策》、《微信官网》、《京东隐私政策》；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uef26dbca">
          2、第三方SDK“友盟+”、“百度”、“获得场景（CC视频）”，用于优化产品性能，<span style="color:#ee2e2e">包括统计数据崩溃信息，需要收集您的设备信息(BSSID),设备Mac地址、设备 ID（包括：androidid以及idfv）、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、IOS广告标识符（IDFA）、SIM识别卡（ICCID）、国际移动用户识别码（IMSI）、ANDROID，ID信息、设备型号、终端制造厂商、终端设备操作系统版本、联网信息、系统名称、系统版本以及国家码</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ufde25795">
          <span style="color:#ee2e2e">卡IMSI信息）</span>以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。您可以进一步查阅《友盟隐私政策》、《百度隐私策略》、《获得场景隐私策略》；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ue6eceeda"> 3、平台名称：极光
          使用SDK名称：极光推送
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ud99556e6">
          服务类型：用于实现消息推送(或其他推送)功能
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uf96eca0c">
          收集个人信息类型：<span style="color:#ee2e2e">设备信息(BSSID)设备 ID（包括：androidid
              以及idfv）、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、IOS广告标识符（IDFA）、SIM识别卡（ICCID）、国际移动用户识别码（IMSI）、ANDROID，ID信息、设备型号、终端制造厂商、终端设备操作系统版本、联网信息、系统名称、系统版本以及国家码</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u45e4c5ec">
          <span style="color:#ee2e2e">、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）)、地理位置、网络信息(IP地址，WiFi信息，基站信息等相关信息)</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u6f8ae7bb"> 4 平台名称：阿里巴巴
          使用SDK名称：Alipay（支付宝） 服务类型：支付
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uae8a1176"> 收集个人信息类型：<span style="color:#ee2e2e">设备信息(BSSID)设备 ID（包括：androidid
              以及idfv）、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、IOS广告标识符（IDFA）、SIM识别卡（ICCID）、国际移动用户识别码（IMSI）、ANDROID，ID信息、设备型号、终端制造厂商、终端设备操作系统版本、联网信息、系统名称、系统版本以及国家码</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uf1567706"> 隐私权政策链接：
        </ne-text>
        <a class="ne-link"
           href="https://opendocs.alipay.com/open/01g6qm#Android%20%C2%A0SDK"
           target="_blank" rel="noopener">
          <ne-text id="u59100277">
            https://opendocs.alipay.com/open/01g6qm#Android%20%C2%A0SDK
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u2b332b19">
          4、第三方SDK“乐播投屏”，为了用于支持产品功能模块，包括在线直播、视频播放、视频投屏、客服，<span style="color:#ee2e2e">需要获取您的设备信息</span>，您可以进一步查阅《乐播隐私政策》；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u5f2e1da6"> 5.平台名称：百度
          使用SDK名称：百度统计
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uafc864be">
          服务类型：<span style="color:#ee2e2e">数据报表、API等形式提供的具有统计、分析等功能的服务，精准提供相关的服务</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u3efa9ecc"> 收集个人信息类型：<span style="color:#ee2e2e">设备信息(BSSID)设备 ID（包括：androidid
              以及idfv）、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、IOS广告标识符（IDFA）、SIM识别卡（ICCID）、国际移动用户识别码（IMSI）、ANDROID，ID信息、设备型号、终端制造厂商、终端设备操作系统版本、联网信息、系统名称、系统版本以及国家码</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc62c5ab7"> 隐私权政策链接：
        </ne-text>
        <a class="ne-link"
           href="https://mtj.baidu.com/static/userguide/book/contents/rules.html"
           target="_blank" rel="noopener">
          <ne-text id="ubb4d1063">
            <span style="color:#ee2e2e">https://mtj.baidu.com/static/userguide/book/contents/rules.html</span>
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ua3353abc"> 6.平台名称：腾讯
          使用SDK名称：腾讯 Bugly （包含Tinker）
          服务类型：<span style="color:#ee2e2e">收集错误日志 ，修复产品问题</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc0738289">
          收集个人信息类型：<span style="color:#ee2e2e">日志信息（包括：第三方开发者自定义日志、Logcat
            日志以及 APP崩溃堆栈信息）、设备 ID（包括：androidid
              以及idfv）、联网信息、系统名称、系统版本以及国家码</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u32299a31"> 隐私权政策链接：
        </ne-text>
        <a class="ne-link"
           href="https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf"
           target="_blank" rel="noopener">
          <ne-text id="ubd4af546">
            https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u39f72ddc"> 7.平台名称：CC
          使用SDK名称：获得场景视频云点播SDK
          （内含声网SDK（io.agora））
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-text-indent="true">
        <ne-text id="u66c7d316"> 服务类型：云点播
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u41ff4372"> 收集个人信息类型：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u306ada2d">
          <span style="color:#ee2e2e">设备型号</span>：用户设备型号，针对设备进行调优
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u45f1b540">
          操作系统版本：用户设备的操作系统，针对系统进行调优
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ubdee9091">
          <span style="color:#ee2e2e">唯一设备标识 AndroidId</span> ：用户生成唯一标识符，针对设备进行调优
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u04279afb">
          <span style="color:#ee2e2e">IP地址</span>：用户网络的IP地址，针对网络进行调优
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ud4b57025">
          <span style="color:#ee2e2e">网络类型(ApnType)</span>：根据不同网络类型，给用户进行使用提示
          <span class="ne-viewer-b-filler"
                ne-filler="block"><br></span>
        </ne-text>
      </ne-p>
      <ne-p
          ne-text-indent="true">
        <ne-text id="u7af9dc35">
          您可以进一步查阅CC隐私政策：
        </ne-text>
        <a class="ne-link"
           href="https://admin.bokecc.com/privacy.bo"
           target="_blank" rel="noopener">
          <ne-text id="u79304f16">
            https://admin.bokecc.com/privacy.bo
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uffaa5273">
          8.平台名称：ShareSDK-社会化登录分享组件
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u13b36711">
          使用SDK名称：ShareSDK （内含 【微博分享 微信分享 QQ分享
          QQ空间分享】）
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ub71c9e8a">
          服务类型：为App赋予社会化分享的能力
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u77b123f6"> 用途：社交分享，第三方登录
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u8f06141e"> 数据是否加密传输：是
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ub496e693">
          您可以进一步查阅《Mob隐私政策》、新浪的《隐私政策》以QQ分享的隐私政策
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u7dcee95d">
          MOB隐私政策链接：www.mob.com/about/policy
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-indent="1">
        <ne-text id="u13ca1e13"> QQ互联隐私政策链接：
        </ne-text>
        <a class="ne-link"
           href="https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e"
           target="_blank" rel="noopener">
          <ne-text id="u3d3a6149">
            https://wiki.connect.qq.com/qq/互联sdk隐私保护声明
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uea511b8b">
          收集个人信息类型：<span style="color:#ee2e2e">设备信息(BSSID)设备 ID（包括：androidid以及idfv）、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、IOS广告标识符（IDFA）、SIM识别卡（ICCID）、国际移动用户识别码（IMSI）、ANDROID，ID信息、设备型号、终端制造厂商、终端设备操作系统版本、联网信息、系统名称、系统版本以及国家码、iOS广告标识符（IDFA）、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID、地理位置</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u4b831e6a"> 9.平台名称：
        </ne-text>
        <ne-text id="u765bff42">
          微信Open SDK
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u18381db5"> 使用SDK名称：
        </ne-text>
        <ne-text id="u3f7a4174">
          Open SDK
        </ne-text>
        <ne-text id="udd13a6a6"> （内含 QQ互联）
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u65821541"> 用途：微信支付，微信分享
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ua029654d"> 数据是否加密传输：是
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-text-indent="true">
        <ne-text id="u3cf557db">
          您可以进一步查阅《微信Open SDK个人信息处理规则》、腾讯的《隐私政策》
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-text-indent="true">
        <ne-text id="u28321a4f"> 1： 微信隐私政策链接：
        </ne-text>
        <a class="ne-link"
           href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8"
           target="_blank" rel="noopener">
          <ne-text id="u168a2219">
            https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-indent="1">
        <ne-text id="u2fed4d34"> 2：腾讯隐私政策链接
        </ne-text>
        <a class="ne-link"
           href="https://privacy.qq.com/policy/tencent-privacypolicy"
           target="_blank" rel="noopener">
          <ne-text id="uc54b4e8d">
            https://privacy.qq.com/policy/tencent-privacypolicy
          </ne-text>
        </a>
        <ne-text id="ub8606be4"></ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-indent="1">
        <ne-text id="u9b042a62"> 10.平台名称：腾讯浏览服务
          SDK
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u246eac7b">
          使用SDK名称：X5内核SDK
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc565b203"> 用途：网页加载，优化体验
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u158362c9"> 数据是否加密传输：是
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-text-indent="true">
        <ne-text id="uec47d9f7"> 您可以进一步查阅《腾讯浏览服务
          SDK个人信息保护规则》、腾讯的《隐私政策》
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-text-indent="true">
        <ne-text id="ue1cc861f"> 1：X5隐私政策链接：
        </ne-text>
        <a class="ne-link"
           href="https://rule.tencent.com/rule/preview/1c4e2b4b-d0f6-4a75-a5c6-1cfce00a390d"
           target="_blank" rel="noopener">
          <ne-text id="ud012a66c">
            https://rule.tencent.com/rule/preview/1c4e2b4b-d0f6-4a75-a5c6-1cfce00a390d
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-indent="1">
        <ne-text id="ubff14537"> 2：腾讯隐私政策链接
        </ne-text>
        <a class="ne-link"
           href="https://privacy.qq.com/policy/tencent-privacypolicy"
           target="_blank" rel="noopener">
          <ne-text id="uc65dc92b">
            https://privacy.qq.com/policy/tencent-privacypolicy
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-indent="1">
        <ne-text id="u2b238893"> 11.平台名称：QQ互联
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u23b02093">
          使用SDK名称：QQ互联SDK
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ucd7f6f13"> 用途：QQ分享
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u82758ef4"> 数据是否加密传输：是
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-text-indent="true">
        <ne-text id="u5dcc0477"> 您可以进一步查阅《QQ互联
          SDK个人信息保护规则》、腾讯的《隐私政策》
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-text-indent="true">
        <ne-text id="u10141b0e"> 1：腾讯隐私政策链接
        </ne-text>
        <a class="ne-link"
           href="https://privacy.qq.com/policy/tencent-privacypolicy"
           target="_blank" rel="noopener">
          <ne-text id="u2fbf5d4b">
            https://privacy.qq.com/policy/tencent-privacypolicy
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-indent="1">
        <ne-text id="ue2bafd10"> 2：QQ互联隐私政策链接：
        </ne-text>
        <a class="ne-link"
           href="https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e"
           target="_blank" rel="noopener">
          <ne-text id="u9f7d89a6">
            https://wiki.connect.qq.com/qq/互联sdk隐私保护声明
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ud15494b8">
          收集个人信息类型：<span style="color:#ee2e2e">系统运行信息、网络状态信息、iOS广告标识符（IDFA）、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID、地理位置</span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ua3b1be52">
          第三方机构名称：上海游昆信息技术有限公司
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u4b831e6a"> 12.平台名称：
        </ne-text>
        <ne-text id="u765bff42">
          VIVO推送
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u18381db5"> 使用SDK名称：
        </ne-text>
        <ne-text id="u3f7a4174">
          VIVO 推送 SDK
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text > 使用目的：提供推送消息服务、统计推送 SDK 接口调用成功率
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u65821541"> 使用场景：在 vivo 手机终端推送消息时使用
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text >
              <span style="color:#ee2e2e">涉及的个人信息类型：应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息
              </span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text >第三方主体：维沃移动通信有限公司</ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-text-indent="true">
        <ne-text id="u3cf557db">
          您可以进一步查阅《VIVO 隐私政策》
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-text-indent="true">
        <ne-text id="u28321a4f"> 1：官网链接：</ne-text>
        <a class="ne-link"
           href="https://dev.vivo.com.cn/promote/pushNews"
           target="_blank" rel="noopener">
          <ne-text id="u168a2219">
            https://dev.vivo.com.cn/promote/pushNews
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-indent="1">
        <ne-text id="u2fed4d34"> 2：隐私政策:
        </ne-text>
        <a class="ne-link"
           href="https://dev.vivo.com.cn/documentCenter/doc/652#w1-12075822"
           target="_blank" rel="noopener">
          <ne-text id="uc54b4e8d">
            https://dev.vivo.com.cn/documentCenter/doc/652#w1-12075822
          </ne-text>
        </a>
        <ne-text id="ub8606be4"></ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u4b831e6a"> 13.平台名称：
        </ne-text>
        <ne-text id="u765bff42">
          VIVO推送
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u18381db5"> 使用SDK名称：
        </ne-text>
        <ne-text id="u3f7a4174">
          OPPO推送 SDK
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text > 使用目的：提供推送消息服务、统计推送 SDK 接口调用成功率
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u65821541"> 使用场景：在 OPPO 手机终端推送消息时使用
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text >
              <span style="color:#ee2e2e">涉及的个人信息类型：应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息
              </span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text >第三方主体：广东欢太科技有限公司</ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-text-indent="true">
        <ne-text id="u3cf557db">
          您可以进一步查阅《OPPO 隐私政策》
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-text-indent="true">
        <ne-text id="u28321a4f"> 1：官网链接：</ne-text>
        <a class="ne-link"
           href="https://open.oppomobile.com"
           target="_blank" rel="noopener">
          <ne-text id="u168a2219">
            https://open.oppomobile.com
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-indent="1">
        <ne-text id="u2fed4d34"> 2：隐私政策:
        </ne-text>
        <a class="ne-link"
           href="https://open.oppomobile.com/new/developmentDoc/info?id=10288"
           target="_blank" rel="noopener">
          <ne-text id="uc54b4e8d">
            https://open.oppomobile.com/new/developmentDoc/info?id=10288
          </ne-text>
        </a>
        <ne-text id="ub8606be4"></ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>

      <ne-p>
        <ne-text id="u4b831e6a"> 14.平台名称：
        </ne-text>
        <ne-text id="u765bff42">
          小米推送
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u18381db5"> 使用SDK名称：
        </ne-text>
        <ne-text id="u3f7a4174">
          小米 推送 SDK
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text > 使用目的：提供推送消息服务、统计推送 SDK 接口调用成功率
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u65821541"> 使用场景：在 小米 手机终端推送消息时使用
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text >
              <span style="color:#ee2e2e">涉及的个人信息类型：应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息
              </span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text >第三方主体：北京小米移动软件有限公司</ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-text-indent="true">
        <ne-text id="u3cf557db">
          您可以进一步查阅《小米隐私政策》
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-text-indent="true">
        <ne-text id="u28321a4f"> 1：官网链接：</ne-text>
        <a class="ne-link"
           href="https://dev.vivo.com.cn/promote/pushNews"
           target="_blank" rel="noopener">
          <ne-text id="u168a2219">
            https://dev.vivo.com.cn/promote/pushNews
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-indent="1">
        <ne-text id="u2fed4d34"> 2：隐私政策:
        </ne-text>
        <a class="ne-link"
           href="https://dev.vivo.com.cn/documentCenter/doc/652#w1-12075822"
           target="_blank" rel="noopener">
          <ne-text id="uc54b4e8d">
            https://dev.vivo.com.cn/documentCenter/doc/652#w1-12075822
          </ne-text>
        </a>
        <ne-text id="ub8606be4"></ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>

      <ne-p>
        <ne-text id="u4b831e6a"> 15.平台名称：
        </ne-text>
        <ne-text id="u765bff42">
          华为 HMS
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u18381db5"> 使用SDK名称：
        </ne-text>
        <ne-text id="u3f7a4174">
          华为 HMS SDK
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text > 使用目的：提供推送消息服务、统计推送 SDK 接口调用成功率
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u65821541"> 使用场景：在 华为 手机终端推送消息时使用
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text >
              <span style="color:#ee2e2e">涉及的个人信息类型：应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息
              </span>
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text >第三方主体：华为软件技术有限公司</ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p ne-text-indent="true">
        <ne-text id="u3cf557db">
          您可以进一步查阅《华为 HMS SDK 隐私政策》
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-text-indent="true">
        <ne-text id="u28321a4f"> 1：官网链接：</ne-text>
        <a class="ne-link"
           href="https://developer.huawei.com/consumer/cn/"
           target="_blank" rel="noopener">
          <ne-text id="u168a2219">
            https://developer.huawei.com/consumer/cn/
          </ne-text>
        </a><span class="ne-viewer-b-filler"
                  ne-filler="block"><br></span>
      </ne-p>
      <ne-p
          ne-indent="1">
        <ne-text id="u2fed4d34"> 2：隐私政策:
        </ne-text>
        <a class="ne-link"
           href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177"
           target="_blank" rel="noopener">
          <ne-text id="uc54b4e8d">
            https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177
          </ne-text>
        </a>
        <ne-text id="ub8606be4"></ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>













      <ne-p>
        <ne-text id="u2a84e8db">
          如上所述服务由相关的第三方负责运营。您使用该等第三方服务（包括您向该等第三方提供的任何个人信息），须受第三方自己的服务条款及个人信息保护声明（而非本政策）约束，您需要仔细阅读其条款。我们仅会出于正当、必要、特定的目的共享您的信息。我们会要求他们履行相关保密义务并采取相应的安全措施。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u6e068e82"> （四）您的个人信息使用规则
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ue0ddce81">
          1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u85816296">
          2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u2d3afec9">
          3、请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u35c81d48">
          4、我们会对我们的产品或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc1b43451">
          5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="udccdfd1f">
          6、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ubf531fe2"> 二、我们如何使用 Cookie
          和同类技术
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ubeae4f49"> （一）Cookie的使用
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u1e948d22">
          1、为了使您获得更优质的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤，帮助判断您的登录状态以及账户或数据安全。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ucff5d2fd"> 2、我们不会将
          Cookies用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。您可以清除计算机上保存的所有Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问筑龙学社网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ucefa63a7"> （二）网站信标和像素标签 除
          Cookie外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ub2b4dd54"> （三）Do Not
          Track（请勿追踪） 很多网络浏览器均设有 Do Not
          Track功能，该功能可向网站发布 Do Not
          Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do
          Not Track，那么我们的所有网站都会尊重您的选择。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc1a70af1">
          三、我们如何共享、转让、公开披露您的个人信息
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u54043c1f"> （一）共享</ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u44fb18b5">
          1、我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u9d271f6a">
          （1）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u2094add0">
          （2）我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u08a51652">
          （3）在法律法规允许的范围内，为维护筑龙学社、筑龙学社的关联方或合作伙伴、您或其他筑龙学社用户或社会公众利益、财产或安全免遭损害而有必要提供；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u51778ee0">
          （4）只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u173a8c6a">
          （5）应您需求为您处理您与他人的纠纷或争议；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u2144c789">
          （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u096461ec"> （7）基于学术研究而使用；
          （8）基于符合法律法规的社会公共利益而使用。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u7dca85b2">
          3、我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uf3321dcf">
          4、我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u3b613890">
          我们的合作伙伴包括以下类型：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u22a799c1">
          （1）商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u47e235bd">
          （2）第三方商家。我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ua0cc5b98">
          （3）授权合作伙伴。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，我们聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。在这种情况下，这些公司必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc4e7e40e">
          5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护筑龙学社、您或其他筑龙学社客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u27ab580d"> （二）转让
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u5039fb4e">
          1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u946a16ea">
          2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="udbbc4d90"> （三）公开披露
          我们仅会在以下情况下，公开披露您的个人信息：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u4d347f54"> 1、获得您明确同意后；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u61a4a524">
          2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u616a7833">
          四、我们如何保护您的个人信息
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u8688f01d">
          （一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受SSL
          加密保护；我们同时对我们网站提供
          https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u3b087504">
          （二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u8f28a1be">
          （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u287098d2">
          （四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u1d3bc5bc">
          （五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u98c15ed8"> 五、您的权利
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>>
        <ne-text >
          按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>>
        <ne-text id="u65061683"> （一）访问您的个人信息
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="udf0b9ea0">
          您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ufea833b9">
          1、账户信息：您可以在“个人中心-个人信息”中访问或编辑您的账户中的个人资料信息、更改您的密码；在个人中心-地址管理更改地址信息；在个人中心-设置中关闭您的账户。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ud302ce6a">
          2、订单信息：您可以在“个人中心-我的订单”中查看您的所有已完成、待付款或已取消的订单信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc16ec102">
          3、商品信息：您可以在“个人中心”查看全部已购买、已兑换或领取的课程商品以及课程详情。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uf8d2f7a1">
          4、学习报告信息：您可以在“个人中心-已购课程”中查看个人的学习记录，包括课程学习进度、学习时长以及完成率等信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u8d2f6259">
          5、对于您在使用我们的产品或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc6a6c3a1"> （二）删除您的个人信息
          在以下情形中，您可以向我们提出删除个人信息的请求：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u425f4f51">
          1、如果我们处理个人信息的行为违反法律法规；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ud060ec36">
          2、如果我们收集、使用您的个人信息，却未征得您的同意；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u29318f76">
          3、如果我们处理个人信息的行为违反了与您的约定；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uaa95a971">
          4、如果您不再使用我们的产品或服务，或您注销了账号；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ua33c513a">
          5、如果我们不再为您提供产品或服务。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u90ce4c78">
          若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u57fe6126">
          我们不会在网络上存储任何用户的隐私信息，所有用户的隐私信息都将直接存储在用户自己本地手机。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u003b3691"> （三）改变您授权同意的范围
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u30600209">
          每个业务功能需要一些基本的个人信息才能得以完成（见本策略“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u590934ce">
          当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uced8b3cf">
          （四）如果您不想接受我们给你发送的促销信息，您随时可通过以下方式取消:
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u8968f337">
          1、您可以随时回复“TD”来取消我们给您发送的手机促销信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ue0efe9f2">
          2、您可以随时通过移动端“系统设置-推送消息设置”来设置是否接受我们通过“通知”推送给您的促销信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u0598453c">
          （五）用户账号注销：您可以通过在线服务，App点击我的—设置—账号与安全—下方的账号注销按钮
          或
          电话服务渠道联系平台，提供相关信息后，平台会帮您注销账号，提交账号注销申请，在接收到您的请求后，我们将在7个工作日内为您完成注销。在您注销账号前，我们会要求您进行必要的身份验证，包括个人身份、安全状态、设备信息等，有关注销的流程和内容，请参见账号注销页面的指引。您知悉并理解，注销账号的行为是不可逆的行为，在您注销账号后，我们将停止为您提供给服务，并将删除有关您账号的一切信息或对相关信息进行匿名化处理，但法律法规另有规定的除外。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u60003bd3"> （六）响应您的上述请求
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u36d9199e">
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15个工作日内内做出答复。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u6c5e492a">
          在以下情形中，按照法律法规要求，我们将无法响应您的请求：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u6c6e5184">
          1、与国家安全、国防安全有关的；
          2、与公共安全、公共卫生、重大公共利益有关的；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="ucc8f26c0">
          3、与犯罪侦查、起诉和审判等有关的；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uf821db1a">
          4、有充分证据表明您存在主观恶意或滥用权利的；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uec4713a4">
          5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u3cd56b6a"> 六、未成年人的个人信息保护
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u8528b3a6">
          1、我们的产品、网站和服务主要面向成人。若您是18周岁以下的未成年人，在使用我们的产品或服务前，应事先取得您家长或法定监护人的书面同意。筑龙学社根据国家相关法律法规的规定保护未成年人的个人信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u5685c626">
          2、对于经父母同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u0437af47">
          七、您的个人信息如何在全球范围转移
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u0390853d">
          我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u2acc8cf4"> 1、法律法规有明确规定；
          2、获得您的明确授权；
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="u308c1774">
          3、您通过互联网进行跨境交易等个人主动行为。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uec9bb057">
          针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uef2f0d82"> 八、本隐私政策如何更新
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uc54a5f0c">
          我们可能适时会对本隐私政策进行调整或变更，本隐私政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私政策并受其约束。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>
        <ne-text id="uf0b5d266"> 九、如何联系我们
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
      <ne-p>>
        <ne-text >
          如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：
        </ne-text>
        <span class="ne-viewer-b-filler"><br></span>
      </ne-p>
      <ne-p>>
        <ne-text > 电话：4009008066
          一般情况下，我们将在15个工作日内内回复。
        </ne-text>
        <span class="ne-viewer-b-filler"
              ne-filler="block"><br></span>
      </ne-p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
  },
}
</script>

<style lang="scss" scoped>
.main {
  background-color: white;
  width: calc(100% - 100);
  padding: 24px 50px;
  min-height: 600px;
  font-family: MicrosoftYaHei;
  margin-top: 60px;
  text-align: left;
}

.yq-blank-detection.module-error {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  flex-direction: column;
  text-align: center;
}

.yq-blank-detection.module-error h3 {
  font-size: 28px;
  font-weight: normal;
  color: #262626;
  margin-bottom: 16px;
}

.yq-blank-detection.module-error .error-message {
  color: #8c8c8c;
  font-size: 14px;
}

.yq-blank-detection.module-error .error-icon-1 {
  max-width: 45px;
  position: relative;
  left: 80px;
  top: -60px;
}

.yq-blank-detection.module-error .error-icon-2 {
  max-width: 54px;
  object-fit: contain;
  position: relative;
  left: -300px;
  top: 50px;
}

.yq-blank-detection.module-error .unknown-error {
  width: 100%;
  max-width: 1080px;
  margin-top: 50px;
}

.yq-blank-detection.module-error button {
  margin-top: 24px;
  color: white;
  background-color: rgb(83, 182, 114);
}

@media screen and (max-width: 768px) {
  .yq-blank-detection.module-error .unknown-error {
    max-width: 100%;
  }
  .yq-blank-detection.module-error .error-icon-2 {
    display: none;
  }
}
</style>
